import React from "react";
import { Modal } from "react-bootstrap";


const ModalComponent = (props) => {

  const {
    header,
    body,
    footer,
    show,
    canClose,
    onHide,
    scroll,
    dialogClassName,
    isChildModalRendering = false,
    isHeaderCenterJustified = false,
    ...restOfProps
  } = props;

  return (
    <>
      <Modal
        dialogClassName={dialogClassName || ""}
        style={scroll ? { display: 'block' } : { width: 'max-content', justifyContent: 'center', marginLeft: '35%', height: '100%' }}
        show={show}
        onHide={canClose ? () => onHide(false) : () => onHide(true)} {...restOfProps}>
        <div style={{ position: 'relative', width:'auto' }}>
          <Modal.Header className={"bg-complement-3"} style={isHeaderCenterJustified ? { justifyContent: 'center' } : {}}>
            <Modal.Title>{header}</Modal.Title>
          </Modal.Header>
          <Modal.Body style={scroll ? { height: 'auto', maxHeight: '60vh', overflowY: 'auto' } : null}>{body}</Modal.Body>
          <Modal.Footer>
            {footer}
          </Modal.Footer>

          {/* HTML element to disable parent modal when a child modal is rendering */}
          {
            isChildModalRendering
            &&
            <div style={{
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0,0,0,0.60)',
              position: 'absolute',
              top: '0',
              left: '0',
            }}>
            </div>
          }
        </div>
      </Modal>
    </>
  );

};

export default ModalComponent;