import React, { useState } from "react";
import { Document, Page } from "react-pdf";
import { Pagination } from "react-bootstrap";

const RenderPdfDocument = ({ doc, currentDocument }) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(null);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <div className="max-w-80 p-2">
      <div className="pagination-render-document">
        <Pagination size="sm">
          {numPages > 1 ?
            <Pagination.First onClick={() => setPageNumber(1)} /> : ''
          }
          {numPages > 1 ?
            <Pagination.Prev
              onClick={() => {
                if (pageNumber > 1) setPageNumber(pageNumber - 1)
              }}
            /> : ''
          }
          {numPages >= 1 ?
            <Pagination.Item
              active={numPages === 1 ? true : pageNumber !== numPages}
              onClick={() => {
                if (pageNumber === numPages) {
                  setPageNumber(1)
                } else {
                  setPageNumber(pageNumber)
                }
              }}
            >
              {pageNumber === numPages ? 1 : pageNumber}
            </Pagination.Item> : ''
          }
          {numPages > 2 ?
            <Pagination.Ellipsis /> : ''
          }
          {numPages > 1 ?
            <Pagination.Item
              active={pageNumber === numPages}
              onClick={() => setPageNumber(numPages)}
            >
              {numPages}
            </Pagination.Item> : ''
          }
          {numPages > 1 ?
            <Pagination.Next
              onClick={() => {
                if (pageNumber < numPages) setPageNumber(pageNumber + 1)
              }}
            /> : ''
          }
          {numPages > 1 ?
            <Pagination.Last
              onClick={() => setPageNumber(numPages)}
            /> : ''
          }
        </Pagination>
      </div>
      <div style={{ textAlign: 'center' }}>
        <b>Página {pageNumber} de {numPages}</b>
      </div>
      {currentDocument === doc &&
      <div className="text-center mt-2">
        <Document
          loading="Por favor espera, estamos preparando la visualización del documento..."
          file={doc}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page pageNumber={pageNumber} />
        </Document>
      </div>
      }
    </div>
  );
};

export default RenderPdfDocument;